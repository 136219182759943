<template>
  <v-layout align-center
            justify-center
            fill-height>
    <v-flex class="mb-12"
            xs12
            sm6
            md4
            lg3>
      <v-card class="elevation-12">
        <v-toolbar dark
                   color="primary">
          <v-toolbar-title>Anmeldung</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-row class="my-0">
            <v-col md="12">
              <div class="text-center">
                <rotating-image :images="$root.config.login_images"
                                alt_text="login_image" />
              </div>
              <p class="text-center mb-0">
                Server: {{ $root.config.server_name }}
              </p>
              <p class="text-center">
                DB: {{ $root.config.database_name }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-form>
          <v-card-text>
            <v-text-field type="text"
                          v-model="form.data.login"
                          :error-messages="form.errByField.login"
                          autofocus
                          prepend-icon="mdi-account"
                          outline
                          label="Login-Name"></v-text-field>
            <v-text-field type="password"
                          v-model="form.data.password"
                          :error-messages="form.errByField.password"
                          prepend-icon="mdi-lock"
                          outline
                          label="Passwort"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <router-link link
                         :to="{ name: 'mobile_apps_menu' }"
                         v-if="$root.config.mobile_apps_enabled">
              <v-btn color="secondary"> Mobile Apps </v-btn>
            </router-link>
            <v-spacer />
            <v-btn type="submit"
                   color="primary"
                   dark
                   @click.stop.prevent="login">
              Anmelden
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import RotatingImage from "components/rotating_image.vue";
import MaintenanceWarning from "components/maintenance_warning.vue";

export default {
  components: { RotatingImage, MaintenanceWarning },
  name: "Login",
  props: {
    debug: Boolean,
    baseUrl: String,
  },
  data: () => ({
    form: {
      data: {},
      errors: [],
      errByField: {},
    },
  }),
  created() {
    this.debug && console.log(`component Login created...`);
    this.debug && console.log(this.$route);
    this.debug && console.log(`baseUrl: ${this.baseUrl}`);
  },
  mounted() {
    this.debug && console.log(`component Login mounted...`);
    // this.$root.$refs.mainapp.$refs.appBarTitle.innerHTML = "Login";
  },
  methods: {
    login() {
      this.form.errors = [];
      this.form.errByField = {};

      this.$http({
        method: "post",
        url: `${this.baseUrl.replace(/\/$/, '')}${this.$route.path}`,
        data: this.form.data,
      })
        .then((response) => {
          if (response.data && response.data.csrf && response.data.access) {
            localStorage.setItem('csrf', response.data.csrf)
            localStorage.setItem('access_token', response.data.access)
          }

          if (this.$root.referrer) {
            window.location.href = this.$root.referrer
          } else {
            this.$router.go();
          }
        })
        .catch((e) => {
          for (var fieldkey in e.response.data) {
            this.form.errByField[fieldkey] = [];
            for (var msgkey in e.response.data[fieldkey]) {
              this.form.errors.push(
                `${fieldkey}: ${e.response.data[fieldkey][msgkey]}`
              );
              this.form.errByField[fieldkey].push(
                e.response.data[fieldkey][msgkey]
              );
            }
          }
          this.$forceUpdate();
        });
    },
  },
};
</script>