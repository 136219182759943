// loading Vue and Vuetify stuff
import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)

import Vue from "vue/dist/vue.esm"
import router from "src/routes.js"
import Vuetify from "vuetify/dist/vuetify.js"
import VueI18n from 'vue-i18n'
import "vuetify/dist/vuetify.min.css"
import "roboto-fontface-woff/css/roboto/roboto-fontface.css"
import '@mdi/font/css/materialdesignicons.css'
import ActionCableVue from "actioncable-vue"
// loading the main App
import MainApp from 'components/main.vue'
import de from 'src/locales/de'
import en from 'vuetify/src/locale/en'

Vue.use(Vuetify)
const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        background: "#EEEEEE",
        primary: "#0277BD",
        secondary: "#37474F",
        // accent: "#4aff4d",
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
      },
      dark: {
        primary: "#0277BD",
        secondary: "#37474F",
        // accent: "#4aff4d",
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
      }
    },
    options: {
      customProperties: true
    }
  }
})

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'de',
  messages: {
    de: de,
    en: en
  }
})
import axios from 'axios'
axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector("meta[name=csrf-token]").content;
Vue.prototype.$http = axios
Vue.prototype.$eventbus = new Vue()

import { navigation_menu_builder } from "mixins/navigation_menu_builder"

document.addEventListener('turbolinks:load', () => {
  const root_element = document.getElementById('landing');

  Vue.use(ActionCableVue, {
    debug: false,
    debugLevel: "error",
    connectImmediately: false,
    connectionUrl: `${root_element.dataset.rails_relative_url_root}/cable`
  })

  const Landing = new Vue({
    el: root_element,
    propsData: {
      user: JSON.parse(root_element.dataset.user),
      permissions: JSON.parse(root_element.dataset.permissions),
      config: JSON.parse(root_element.dataset.config),
      main_menu_data: JSON.parse(root_element.dataset.main_menu_data),
      admin_menu_data: JSON.parse(root_element.dataset.admin_menu_data),
      host_url: root_element.dataset.host_url,
      rails_relative_url_root: root_element.dataset.rails_relative_url_root,
      app_url: root_element.dataset.app_url,
      hierarchies4info: JSON.parse(root_element.dataset.hierarchies4info),
      mimetypes: JSON.parse(root_element.dataset.mimetypes),
      referrer: root_element.dataset.referrer
    },
    props: {
      user: Object,
      permissions: Array,
      config: Object,
      main_menu_data: Array,
      admin_menu_data: Array,
      host_url: String,
      rails_relative_url_root: String,
      app_url: String,
      hierarchies4info: Array,
      mimetypes: String,
      referrer: String,
    },
    name: 'Landing',
    vuetify,
    i18n,
    router,
    components: { MainApp },
    mixins: [navigation_menu_builder],
    data: () => ({
      global_modal_width: '75rem',
      debug: false,
      // die folgenden werden per props an die Main-Componente übergeben
      // und von dieser in deren mount-hook decodiert und hier befüllt
      rootUrl: undefined,
      baseUrl: undefined,
      locale: undefined,
      subscription: undefined,
      maintenance_warning: false,
    }),

    created() {
      this.maintenance_warning = this.config.maintenance_warning
      this.debug && console.log(`vue root (landing.js) created ...`)
      var featureDetect = function () {
        var vueTest = "__defineSetter__" in Object.prototype
        var svgTest =
          !!document.createElementNS &&
          !!document.createElementNS("http://www.w3.org/2000/svg", "svg")
            .createSVGRect
        var transitionTest =
          "transition" in document.documentElement.style ||
          "WebkitTransition" in document.documentElement.style
        // alert(vueTest + '//' + svgTest + '//' + transitionTest)
        return vueTest && svgTest && transitionTest
      }
      if (!featureDetect()) {
        alert(
          "Der von Ihnen vewendete Browser kann für die CSMed Anwendung leider nicht verwendet werden. Verwenden Sie bitte einen aktuellen Browser, wie z.B. 'Google Chrome', 'Safari' oder 'Mozilla Firefox'"
        )
        exit
      }

      this.add_maintenance_mode_interceptor()
    },

    mounted() {
      this.debug && console.log(`vue root (landing.js) mounted...`)

      // if (navigator.serviceWorker) {
      //   navigator.serviceWorker
      //     .register(`${this.rootUrl}service-worker.js`, { scope: this.rootUrl })
      //     .then(function (reg) {
      //     })
      // }

      this.disableBackButton()

      router.beforeEach((to, from, next) => {
        document.title = this.config.app_name
        next()
      })
    },

    methods: {
      disableBackButton() {
        window.addEventListener('load', function () {
          window.history.pushState({}, '')
        })

        window.addEventListener('popstate', function () {
          window.history.pushState({}, '')
          alert("Der Zurück-Button wird von dieser App nicht unterstützt!")
        })
      },

      add_maintenance_mode_interceptor() {
        self = this
        this.$http.interceptors.response.use(function (response) {
          if (response.headers['csmed_maintenance_warning'] == 1) {
            self.maintenance_warning = true;
          } else {
            self.maintenance_warning = false;
          }

          if (response.headers['csmed_maintenance_enabled'] == 1) {
            window.location.href = `${self.app_url}/maintenance`
          }

          return response;
        }, function (error) {
          return Promise.reject(error);
        });
      }
    },
  }).$mount()
})
